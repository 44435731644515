@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Urbanist:wght@300;400;500;600;700&display=swap");

/* for calender */

.fc .fc-daygrid .fc-non-business {
  background-color: white;
}
.dark .fc .fc-daygrid .fc-non-business {
  background-color: #23262b;
}

.fc .fc-col-header-cell .fc-col-header-cell-cushion {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #22c55e;
}

.fc-day-sun .fc-col-header-cell-cushion,
.fc-day-sun a.fc-daygrid-day-number {
  color: #ff4747 !important;
}

.fc-day-sat,
.fc-day-sat a.fc-daygrid-day-number {
  color: #ff4747 !important;
}

.fc-button-group button.fc-dayGridMonth-button.fc-button.fc-button-primary,
.fc-button-group button.fc-timeGridWeek-button.fc-button.fc-button-primary,
.fc-button-group button.fc-timeGridDay-button.fc-button.fc-button-primary,
.fc-button-group button.fc-listMonth-button.fc-button.fc-button-primary {
  display: none !important;
}

button.fc-button.fc-button-primary.fc-next-button,
button.fc-button.fc-button-primary.fc-prev-button {
  border: 1px solid gray;
  padding: 10px 5px;
  border-radius: 5px;
  margin-right: 2px;
  outline: none;
}

.MiddletitleButton {
  display: flex;
}
.fc-toolbar-title {
  color: #1a202c;
  font-size: 18px !important;
  font-weight: bold !important;
}
.dark .fc-toolbar-title {
  color: white;
}
.fc-header-toolbar {
  margin-bottom: 24px !important;
}
.fc td,
.fc th {
  border-style: none !important;
}
.fc-scrollgrid {
  padding-top: 20px !important ;
  border-top: 1px solid #e4f2ff !important ;
}
.dark .fc-scrollgrid {
  border-top: 1px solid #2a313c !important ;
}

.fc-scrollgrid td:last-of-type {
  border-right: none !important;
}
.fc-day:first-child > div:first-child {
  position: relative;
}
#calendar td {
  vertical-align: middle !important;
  border: 1px solid #000;
}

.fc {
  text-align: center !important;
}

.fc td {
  padding: 0;
  vertical-align: middle !important;
}

.fc-day .fc-daygrid-day-frame {
  position: relative !important;
  display: flex;
  flex-direction: column-reverse;
}

.fc-day .fc-daygrid-day-frame .fc-daygrid-day-number {
  position: absolute !important;
  top: 50% !important;
  left: 0px !important;
  right: 0px !important;
  text-align: center !important;
  margin-top: -0.5em !important;
  line-height: 1em !important;
  font-weight: 500;
  font-size: 18px;
  color: #2f3032;
}

.fc-daygrid-day-frame .fc-daygrid-day-events {
  display: flex;
  flex-wrap: wrap;
}

.fc-daygrid-day-frame .fc-daygrid-day-events .fc-daygrid-event-harness {
  min-width: 25%;
}

.dark .fc-day .fc-daygrid-day-frame .fc-daygrid-day-number {
  color: white;
}

.fc .fc-daygrid .fc-day-today .fc-daygrid-day-frame {
  border: 2px solid #b7ffd1 !important;
  background: none !important;
}
.fc .fc-day-today {
  background: none !important;
}
/* .fc .fc-timegrid .fc-daygrid-day{
  background: none !important;
} */
.fc .fc-timegrid .fc-timegrid-slot.fc-timegrid-slot-lane {
  border: 1px solid #cbd5e0 !important;
}
.fc .fc-non-business {
  background: none !important;
}
.fc .fc-daygrid .fc-timegrid-col {
  background: none !important;
  border: none !important;
}
.fc .fc-daygrid .fc-day-today .fc-daygrid-day-frame {
  border-radius: 6px;
}
.fc .fc-scrollgrid.fc-scrollgrid-liquid {
  border: none;
}
.fc .fc-view-harness {
  width: 100% !important;
  height: 800px !important;
}
.fc .fc-scrollgrid-sync-table {
  width: 100% !important;
  height: 100% !important;
}

.fc table.fc-col-header {
  width: 100% !important;
}
.fc .fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100% !important;
  height: 100% !important;
}
@keyframes tabFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 1536px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
  .fc .fc-view-harness {
    width: 100% !important;
    height: 600px !important;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 640px) {
  .fc .fc-view-harness {
    width: 100% !important;
    height: 400px !important;
  }
}

/* min calender  */

.min-calender {
  border-color: #2a313c;
  padding: 26px;
  border-radius: 18px;
}

.dark .min-calender {
  background: #23262b;
}

.min-calender .fc .fc-daygrid .fc-day-today {
  background: #22c55e !important;
  color: #ffffff!;
  border: none !important;
  border-radius: 8px !important;
}

.min-calender .fc .fc-daygrid .fc-day-today .fc-daygrid-day-frame {
  border: 0px !important;
}

.min-calender .fc-header-toolbar {
  display: none !important;
}
.min-calender .fc-scrollgrid {
  border-top: 0 !important;
  border-collapse: collapse !important;
}

.min-calender .fc-view-harness {
  max-height: 300px !important;
}

.min-calender {
  background: #f7fafc;
}

.min-calender .fc-daygrid-day-frame {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  height: 20px;
  margin: 2px;
  font-family: "Urbanist", sans-serif;
}

.dark .min-calender .fc-daygrid-day a {
  color: white !important;
}

.min-calender .fc-daygrid-day a {
  color: black !important;
}

.min-calender .fc-daygrid-day:hover {
  background: #e6e6e6 !important;
  color: #000 !important;
  border-radius: 50%;
}

.min-calender .fc-daygrid-day {
  scale: 0.9;
}

.min-calender .fc-daygrid-day:hover a {
  color: #000 !important;
}

.min-calender .fc-daygrid-day-number {
  margin-bottom: 10px !important;
}

.min-calender .fc-day .fc-daygrid-day-frame .fc-daygrid-day-number {
  top: 40% !important;
  text-align: center !important;
  margin-top: 0 !important;
  line-height: 0 !important;
  font-weight: 500;
  font-size: 18px;
}
.min-calender .fc-daygrid-day-top {
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
}

.min-calender .fc-daygrid-day-events {
  display: none !important;
}

.min-calender .fc-col-header-cell a {
  color: #b7b7b7 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-timeGridDay-button,
.fc-listMonth-button {
  display: none !important;
}

.fc-prev-button,
.fc-next-button {
  background: none !important;
  border: none !important;
  color: #1a202c !important;
}

.dark .fc-prev-button,
.dark .fc-next-button {
  color: #8e8e8e !important;
}
.fc-prev-button:focus {
  border: none !important;
}

.fc-timegrid-slot-label-cushion,
.fc-timegrid-axis-cushion {
  color: #000;
}
.dark .fc-timegrid-slot-label-cushion,
.dark .fc-timegrid-axis-cushion {
  color: #ffffff;
}

.fc-prev-button:focus,
.fc-next-button:focus {
  outline: 4px solid white !important;
}

.dark .fc-prev-button:focus,
.dark .fc-next-button:focus {
  outline: 4px solid #1d1e24 !important;
}

.dark .fc-daygrid-dot-event .fc-event-title {
  color: white;
}

.fc-event-main .fc-event-title {
  padding: 10px;
}

.fc-slot-attributes {
  display: flex;
  align-items: center;
  margin: 0 2px;
  min-height: 30px;
  height: fit-content;
}

.fc-popover-body div {
  margin: 2px 0;
}